export const socialIcons = [
  {
    name: "GitHub",
    src: "/github.svg",
    link: "https://github.com/Dylanos03",
  },
  {
    name: "LinkedIn",
    src: "/linkedin.svg",
    link: "https://www.linkedin.com/in/dylan-j-osullivan/",
  },
  {
    name: "Instagram",
    src: "/instagram.svg",
    link: "https://www.instagram.com/dd.dylanos/",
  },
];
