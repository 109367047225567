export const techIcons = [
  {
    id: 1,
    name: "HTML",
    src: "/html5.svg",
  },
  {
    id: 2,
    name: "CSS",
    src: "/css3.svg",
  },
  {
    id: 3,
    name: "JavaScript",
    src: "/javascript.svg",
  },
  {
    id: 4,
    name: "TypeScript",
    src: "/typescript.svg",
  },
  {
    id: 5,
    name: "React",
    src: "/reactjs.svg",
  },
  {
    id: 6,
    name: "npm",
    src: "/npm.svg",
  },
  {
    id: 7,
    name: "Git",
    src: "/git.svg",
  },
  {
    id: 8,
    name: "TailwindCSS",
    src: "/tailwindcss.svg",
  },
  {
    id: 9,
    name: "SASS",
    src: "/sass.svg",
  },
  {
    id: 10,
    name: "WordPress",
    src: "/wordpress.svg",
  },
  {
    id: 11,
    name: "Bootstrap",
    src: "/bootstrap.svg",
  },
  {
    id: 12,
    name: "NEXTJS",
    src: "/nextjs.svg",
  },
  {
    id: 13,
    name: "NodeJS",
    src: "/node-js.svg",
  },
  {
    id: 14,
    name: "Prisma",
    src: "/prisma.svg",
  },
  {
    id: 15,
    name: "Stripe",
    src: "/stripe.svg",
  },
];
