export const portfolioImgs = [
  {
    id: 1,
    title: "Spruced Up Stuff",
    src: "/SUS.webp",
  },
  {
    id: 2,
    title: "Umbreco",
    src: "/Umbreco.webp",
  },
  {
    id: 3,
    title: "Norex",
    src: "/Norex.webp",
  },
  {
    id: 4,
    title: "Homeys",
    src: "/Homeys.webp",
  },
  {
    id: 5,
    title: "Page Writer Pro",
    src: "/PageWriterPro.webp",
  },
];
